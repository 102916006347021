import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';

class TableField extends React.Component {
	state = {
		selectedIndex: this.props.selectedIndex,
		validationChecked: false,
		inputValue: ''
	};

	onChange = (index) => {
		const {onChange, items, questionOrder, id, disabled} = this.props;
		const oldIndex = this.state.selectedIndex;
		!disabled &&
			this.setState(
				{
					validationChecked: true,
					selectedIndex: index
				},
				() => onChange && onChange(questionOrder, items[index], items[oldIndex], id)
			);
	};

	getValue = () => {
		const {selectedIndex, inputValue} = this.state;
		const {items} = this.props;
		if (selectedIndex !== -1) {
			const haveInputText = items[selectedIndex].haveInputText;
			if (haveInputText === 1 || haveInputText === 2 || haveInputText === 3) {
				return inputValue;
			}
			return items[selectedIndex].text;
		}
		return '';
	};

	checkValidation = () => {
		if (!this.props.disabled && this.props.required && this.state.selectedIndex === -1) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	render() {
		const {items, title, className, useSelectedIndexFromProps, disabled, showAnswersInTable} = this.props;
		const {validationChecked} = this.state;
		const validationObject = this.checkValidation();
		const tableFieldClassName = classnames('table-field', 'radio-group', {disabled: disabled}, {[className]: !!className});
		const {selectedIndex} = useSelectedIndexFromProps ? this.props : this.state;
		return (
			<>
				<div className={tableFieldClassName}>
					{showAnswersInTable && (
						<div className="table-field-header">
							{items.map((item, index) => {
								return (
									<div key={index} className="table-field-header__wrapper">
										{showAnswersInTable && <span className="table-field-header__text">{item.text}</span>}
									</div>
								);
							})}
						</div>
					)}
					{title && <span className="table-field__title">{title}</span>}
					{items.map((item, index) => {
						const classes = `radio-button ${index === selectedIndex ? 'active' : ''}`;
						return (
							<div key={index} className="radio-group-item">
								<div className="radio-group-content" onClick={() => this.onChange(index)}>
									<div className={classes} />
								</div>
							</div>
						);
					})}
					{!validationObject.isValid && validationChecked && <div className="error-message">{validationObject.message}</div>}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({questionData}, ownProps) => {
	const {userAnswers} = questionData;
	const {id, items} = ownProps;

	let selectedIndex = -1;

	for (let index = 0; index < userAnswers.length; index++) {
		if (userAnswers[index].questionId === id) {
			const answerId = userAnswers[index].AnswerId;
			selectedIndex = items.findIndex((item) => item.id === answerId);
			break;
		}
	}

	return {
		selectedIndex: selectedIndex
	};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(TableField);

TableField.defaultProps = {
	required: true,
	small: false,
	useSelectedIndexFromProps: true,
	disabled: false
};
