import React, {Component} from 'react';
import SelectField from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import classnames from 'classnames';

export default class Select extends Component {
	state = {
		focus: false,
		selectedOption: this.props.selectedOption || null,
		validationChecked: false
	};

	componentWillReceiveProps(newProps) {
		const {selectedOption} = newProps;
		this.setState({
			selectedOption: selectedOption || null
		});
	}

	onChange = (selectedOption) => {
		this.setState(
			{
				selectedOption: selectedOption
			},
			() => this.props.onChange && this.props.onChange(selectedOption)
		);
	};

	handleFocus = () => {
		this.setState({focus: !this.state.focus});
	};

	handleBlur = () => {
		this.handleFocus();
		this.setState({validationChecked: true});
	};

	checkValidation = () => {
		if (this.state.selectedOption == null) {
			return {
				isValid: false,
				message: 'Ovo polje je obavezno'
			};
		}
		return {isValid: true};
	};

	render() {
		const {iconName, hidden, noOptionsMessage, placeholder, options} = this.props;
		const {focus, selectedOption, validationChecked} = this.state;
		const className = classnames('input-wrapper', {focus: focus}, {'not-visible': hidden});
		const validationObject = this.checkValidation();
		const selectClassName = classnames(
			'select-wrapper',
			{error: !validationObject.isValid && validationChecked},
			{correct: validationChecked && validationObject.isValid}
		);
		return (
			<div className={className}>
				{iconName && (
					<div className="icon">
						<FontAwesomeIcon icon={iconName} />
					</div>
				)}
				<div className={selectClassName}>
					<SelectField
						className="react-select-container"
						classNamePrefix="react-select"
						options={options}
						value={selectedOption}
						noOptionsMessage={() => noOptionsMessage}
						placeholder={placeholder}
						onChange={this.onChange}
						onFocus={this.handleFocus}
						onBlur={this.handleBlur}
					/>
					{!validationObject.isValid && validationChecked && <span className="error-message">{validationObject.message}</span>}
				</div>
			</div>
		);
	}
}
