import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { redirectTo } from '../utils/appUtils';

export default class NavLinkButton extends React.Component {

	handleClick = (e) => {
		if (!this.props.isDisabled) {
			e.preventDefault();
			if (this.props.beforeChange) {
				this.props.beforeChange(this.changeRoute);
			} else {
				this.changeRoute();
			}
		}
	};

	changeRoute = () => {
		const {to} = this.props;
		redirectTo(to);
	};

	render() {
		const {className, to, replace, isDisabled} = this.props;
		const linkClassName = classnames('link-button', {[className]: !!className}, {'disabled': isDisabled})
		return (
			<Link onClick={this.handleClick} to={to} replace={replace} className={linkClassName}>
				{this.props.label}
			</Link>
		);
	}
}

NavLinkButton.propTypes = {
	beforeChange: PropTypes.func.isRequired
};
