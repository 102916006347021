import React from 'react';
import Input from '../input';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateField from '../date';
import {connect} from 'react-redux';
import {isNull} from '../../utils/utils';

class RadioGroup extends React.Component {
	state = {
		selectedIndex: this.props.selectedIndex || -1,
		validationChecked: this.props.validationChecked || false,
		inputValue: ''
	};

	checkDefaultValue = () => {
		const {userAnswers, items} = this.props;
		userAnswers &&
			items.forEach((item, index) => {
				if (!!userAnswers.find((ua) => ua.answerId === item.id)) {
					this.onChange(index);
				}
			});
	};

	onChange = (index) => {
		const {onChange, items, questionOrder, id, disabled, useSelectedIndexFromProps} = this.props;
		const {selectedIndex} = useSelectedIndexFromProps ? this.props : this.state;
		const oldIndex = selectedIndex;
		!disabled && oldIndex !== index &&
			this.setState(
				{
					validationChecked: true,
					selectedIndex: index
				},
				() => onChange && onChange(questionOrder, items[index], items[oldIndex], id)
			);
	};

	getValue = () => {
		const {selectedIndex, inputValue} = this.state;
		const {items} = this.props;
		if (selectedIndex !== -1) {
			const haveInputText = items[selectedIndex].haveInputText;
			if (haveInputText === 1 || haveInputText === 2 || haveInputText === 3) {
				return inputValue;
			}
			return items[selectedIndex].text;
		}
		return '';
	};

	handleOnBlur = () => {
		const validationObject = this.checkValidation();
		this.setState({
			isValid: validationObject.isValid,
			errorMessage: validationObject.message,
			validationChecked: true
		});
		this.props.onBlur && this.props.onBlur();
	};

	checkValidation = () => {
		if (!this.props.disabled && this.props.required) {
			if (this.state.selectedIndex === -1 || this.additionalInputEmpty()) {
				return {
					isValid: false,
					message: 'Ovo polje je obavezno'
				};
			}
		}

		return this.props.checkValidation ? this.props.checkValidation() : {isValid: true};
	};

	additionalInputEmpty = () => {
		const {items} = this.props;
		const {selectedIndex, inputValue} = this.state;
		if (items[selectedIndex] && !isNull(items[selectedIndex].haveInputText) && !inputValue) {
			return true;
		}
		return false;
	};

	getAdditionalInput = () => {
		const {items} = this.props;
		const {selectedIndex, inputValue} = this.state;
		if (items[selectedIndex]) {
			const isInput = items[selectedIndex].haveInputText === 1;
			const isDate = items[selectedIndex].haveInputText === 2 || items[selectedIndex].haveInputText === 3;
			if (isInput) {
				return (
					<div className="additional-input">
						<Input required={false} value={inputValue} onChange={this.inputValueChange} />
					</div>
				);
			} else if (isDate) {
				const isDateBefore = items[selectedIndex].haveInputText === 3;
				if (isDateBefore) {
					return (
						<div className="additional-input">
							<DateField maxDate={new Date()} required={false} checkValidation={false} value={inputValue} onChange={this.dateInputValueChange} />
						</div>
					);
				} else {
					return (
						<div className="additional-input">
							<DateField minDate={new Date()} required={false} checkValidation={false} value={inputValue} onChange={this.dateInputValueChange} />
						</div>
					);
				}
			}
		}
		return <></>;
	};

	dateInputValueChange = (value) => {
		this.setState({
			inputValue: value
		});
	};

	inputValueChange = (e) => {
		this.setState({
			inputValue: e.target.value
		});
	};

	render() {
		const {items, small, iconName, label, className, useSelectedIndexFromProps, disabled} = this.props;
		const {validationChecked} = this.state;
		const validationObject = this.checkValidation();
		const radioGroupClassName = classnames('radio-group', {small: !!small}, {disabled: disabled}, {[className]: !!className});
		const {selectedIndex} = useSelectedIndexFromProps ? this.props : this.state;
		return (
			<>
				<div className={radioGroupClassName}>
					{iconName && (
						<div className="icon">
							<FontAwesomeIcon icon={iconName} />
						</div>
					)}
					{label && <span className="input-label">{label}</span>}
					{items.map((item, index) => {
						const classes = `radio-button ${index === selectedIndex ? 'active' : ''}`;
						return (
							<div key={index} className="radio-group-item">
								<div className="radio-group-content" onClick={() => this.onChange(index)}>
									<div className={classes} />
									{item.text}
								</div>
							</div>
						);
					})}
					{this.getAdditionalInput()}
					{!validationObject.isValid && validationChecked && <div className="error-message">{validationObject.message}</div>}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({questionData}, ownProps) => {
	const {userAnswers} = questionData;
	const {id, items, useStoredIndex} = ownProps;

	let selectedIndex = -1;
	if (useStoredIndex) {

		for (let index = 0; index < userAnswers.length; index++) {
			if (userAnswers[index].questionId === id) {
				const answerId = userAnswers[index].AnswerId;
				selectedIndex = items.findIndex((item) => item.id === answerId);
				break;
			}
		}
		return {
			selectedIndex: selectedIndex
		};
	}
	return {};
};

export default connect(mapStateToProps, null, null, {forwardRef: true})(RadioGroup);

RadioGroup.defaultProps = {
	required: true,
	small: false,
	useSelectedIndexFromProps: true,
	disabled: false,
	useStoredIndex: true
};
